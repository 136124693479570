<template>
    <div class="wrapper">
        <van-nav-bar title="用户登录" @click-left="goHome">
            <template #left>
                <van-icon name="wap-home" size="18" />
            </template>
            <template #right>
                <a @click="goHelpCenter"><van-icon name="question-o" size="18" /></a>
            </template>
        </van-nav-bar>
        <div class="login">
            <div class="login_logo">
                <img src="../../assets/img/logo.png" alt="logo">
                <h3>V聘360登录</h3>
            </div>
            <div class="login_form">
                <van-form @submit="handleLogin">
                    <div class="login_input">
                        <van-field v-model="username" name="用户名" label="用户名" placeholder="请填写"
                                   :rules="[{ required: true, message: '请填写用户名' }]" required clearable />
                        <van-field class="two_words" v-model="password" type="password" name="密码" label="密码"
                                   placeholder="请填写" :rules="[{ required: true, message: '请填写密码' }]" required clearable />
                        <van-field v-model="captcha" maxlength="4" name="验证码" label="验证码" placeholder="请填写"
                                   :rules="[{ required: true, message: '请填写验证码' }]" required clearable>
                            <template #button>
                                <img width="100" height="32" :src="myCodeImg" @click="showCodeImg"/>
                            </template>
                        </van-field>
                    </div>
                    <div class="checkbox">
                        <van-row>
                            <van-col span="12">
                                <van-checkbox v-model="checked" shape="square">记住用户名</van-checkbox>
                            </van-col>
                            <van-col class="forget_password" span="12">
                                <a class="retrieve_password" @click="handleForget">忘记密码？</a>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="submit_btn">
                        <van-button type="primary" native-type="submit" block>登录</van-button>
                    </div>
                    <div class="regist_btn">
                        没有账号？<a class="inline_link" @click="handleRegister">立即注册</a>
                    </div>
                </van-form>
            </div>
            <div class="footer">
                <div class="copyright">Copyright &copy; {{ (new Date).getFullYear() }} 节点速聘（西安）人力资源服务有限公司</div>
            </div>
        </div>
    </div>
</template>
<script>
import {reqCodeImg, reqSystemLogin} from "../../api/user-api";
import {reqClientLogin} from "../../api/client-api";

export default {
    data() {
        return {
            projectCode: '',
            username: '',
            password: '',
            captcha: '',
            myCodeImg: null,
            checked: true, //记住密码开关
            captchaImage: ''
        };
    },
    methods: {
        goHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        handleRegister() {
            this.$router.push({name: 'register', query: {projectCode: this.projectCode}});
        },
        handleForget() {
            this.$router.push({name: 'forgetPassword', query: {projectCode: this.projectCode}});
        },
        //记住用户
        setCookie(username, exdays) {
            let exdate = new Date()
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
            // 字符串拼接cookie
            window.document.cookie = 'username' + '=' + username + ';path=/;expires=' + exdate.toGMTString()
        },
        // 读取cookie
        getCookie() {
            window.document.cookie?.split(';').map(item => {
                if (item.startsWith('username')) {
                    this.username = item.split('=')[1];
                }
            })
        },
        // 清除cookie
        clearCookie() {
            this.setCookie('', -1);
        },
        //展示验证码
        showCodeImg() {
            let that = this;
            reqCodeImg().then((res) => {
                that.myCodeImg = window.URL.createObjectURL(new Blob([res.data]));
                that.captchaImage = res.headers['captcha_key'];
            }).catch(() => {
                that.myCodeImg = null;
                that.captchaImage = "";
                this.$notify({type: 'danger', duration: '1000', message: '获取验证码失败'});
            });
        },
        handleLogin() {
            let password = this.encode64(this.encode64(this.password) + 'pd');
            let username = this.encode64(this.encode64(this.username) + 'un');
            let loginParams = {
                username: username,
                password: password,
                captcha: this.captcha,
                captchaImage: this.captchaImage
            };
            reqClientLogin(loginParams).then(data => {
                this.loading = false;
                if (data.code === 1) {
                    sessionStorage.setItem('un', this.username);
                    sessionStorage.setItem('Authorization', data.data.token);
                    if (this.checked == true) {
                        this.setCookie(this.username, 7);
                    } else {
                        this.clearCookie();
                    }

                    if (sessionStorage.getItem("redirectTo") !== null){
                        location.href = sessionStorage.getItem("redirectTo")
                    }else {
                        this.$router.push({name: 'project', params: {id: this.projectCode}});
                        this.$notify({type: 'success', duration: '1000', message: data.msg});
                    }
                } else {
                    this.$notify({type: 'danger', duration: '1000', message: data.msg});
                    this.password = ''
                    this.captcha = ''
                    this.showCodeImg();
                }
            }).catch(() => {
                this.password = ''
                this.captcha = ''
                this.showCodeImg();
                this.$notify({type: 'danger', duration: '1000', message: '登录异常'});
            })
        },
        // base64加密开始
        encode64(input) {
            let keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv" + "wxyz0123456789+/" + "=";
            let output = "";
            let chr1, chr2, chr3 = "";
            let enc1, enc2, enc3, enc4 = "";
            let i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        }
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getCookie()
        this.showCodeImg()
    }
};
</script>

<style lang="less" scoped>

</style>
