<template>
    <div class="wrapper">
        <div class="header">
            <Row>
                <Col span="18" class="title">
                    <Icon type="md-home" />V聘360
                </Col>
                <Col span="6" class="menu">
                    <a @click="handleRegister">注册</a> ｜ <a @click="goHelpCenter">帮助</a>
                </Col>
            </Row>
        </div>
        <div class="banner"><img src="../../assets/img/default-banner.png" alt=""></div>
        <div class="content">
            <div class="main-content">
                <div class="login_wrapper">
                    <div class="login_text">用户登录</div>
                    <div class="login_form">
                        <Form ref="loginForm" :model="loginForm" :rules="loginFormRule">
                            <FormItem prop="username">
                                <Input type="text" size="large" v-model.trim="loginForm.username" placeholder="手机号码" @keyup.enter.native="handleLogin">
                                    <Icon type="ios-person" slot="prefix"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <Input type="password" size="large" v-model="loginForm.password" clearable placeholder="密码" @keyup.enter.native="handleLogin">
                                    <Icon type="ios-lock" slot="prefix"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="captcha">
                                <Input class="captcha" type="text" size="large" prop="imgCode" maxlength="4" v-model="loginForm.captcha" placeholder="验证码" @keyup.enter.native="handleLogin">
                                    <template slot="append">
                                        <img class="img_code" :src="myCodeImg" @click="showCodeImg"/>
                                    </template>
                                </Input>
                            </FormItem>
                            <FormItem class="checkbox">
                                <Checkbox v-model="checked">记住用户名</Checkbox>
                                <a class="retrieve_password" @click="handleForget">忘记密码？</a>
                            </FormItem>
                            <FormItem>
                                <Button type="primary" size="large" @click.native.prevent="handleLogin" :loading="loading" long>登录</Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import {reqCodeImg} from '../../api/user-api';
import {reqClientLogin} from "../../api/client-api";
import {encode64} from '../../assets/js/utils';
import Footer from "../../components/pc/Footer";

export default {
    components: {
        Footer: Footer
    },
    data() {
        return {
            code: '',
            showForm: false,
            loading: false,
            myCodeImg: null,
            captchaImage: '',
            checked: true,
            loginForm: {
                username: '',
                password: '',
                captcha: ''
            },
            loginFormRule: {
                username: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                captcha: [{required: true, message: '请输入验证码', trigger: 'blur'}]
            }
        }
    },
    methods: {
        handleRegister() {
            this.$router.push({name: 'register', query: {projectCode: this.code}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.code}});
        },
        //记住用户
        setCookie(username, exdays) {
            let exdate = new Date()
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
            // 字符串拼接cookie
            window.document.cookie = 'username' + '=' + username + ';path=/;expires=' + exdate.toGMTString()
        },
        // 读取cookie
        getCookie() {
            window.document.cookie?.split(';').map(item => {
                if (item.startsWith('username')) {
                    this.loginForm.username = item.split('=')[1];
                }
            })
        },
        // 清除cookie
        clearCookie() {
            this.setCookie('', -1);
        },
        //登录
        handleLogin() {
            this.$refs['loginForm'].validate((valid) => {
                if (!valid) {
                    return false;
                }

                this.loading = true;
                let password = encode64(encode64(this.loginForm.password) + 'pd');
                let username = encode64(encode64(this.loginForm.username) + 'un');
                let loginParams = {
                    username: username,
                    password: password,
                    captcha: this.loginForm.captcha,
                    captchaImage: this.captchaImage
                };
                reqClientLogin(loginParams).then(data => {
                    this.loading = false;
                    if (data.code === 1) {
                        this.$Message.success(data.msg);
                        sessionStorage.setItem('un', this.loginForm.username);
                        sessionStorage.setItem('Authorization', data.data.token);
                        if (this.checked == true) {
                            this.setCookie(this.loginForm.username, 7);
                        } else {
                            this.clearCookie();
                        }

                        if (sessionStorage.getItem("redirectTo") !== null && sessionStorage.getItem("redirectTo").includes('/forgetPassword')){
                            location.href = sessionStorage.getItem("redirectTo")
                        }else {
                            this.$router.push({name:'project', params:{id: this.code}});
                        }
                    } else {
                        this.loginForm.captcha = '';
                        this.$Message.error(data.msg);
                        this.showCodeImg();
                    }
                }).catch(() => {
                    this.loading = false;
                    this.loginForm.captcha = '';
                    this.showCodeImg();
                    this.$Message.error('登录异常');
                })
            });
        },
        //展示验证码
        showCodeImg() {
            let that = this;
            reqCodeImg().then((res) => {
                that.myCodeImg = window.URL.createObjectURL(new Blob([res.data]));
                that.captchaImage = res.headers['captcha_key'];
            }).catch(() => {
                that.myCodeImg = null;
                that.captchaImage = "";
                this.$Message.error('获取验证码失败');
            });
        },
        handleForget() {
            this.$router.push({name:'forgetPassword'});
        }
    },
    mounted() {
        this.getCookie()
        this.showCodeImg()
    }
}
</script>
